import { sub } from "date-fns";


// POPOVER, TAKEOVER, INTERSTITIAL LIST
const path = [
  "/event",
  "/latest-event",
  "/editor-choice",
  "/post",
  "/location",
];

function adPlatform(platform?: string) {
  if (platform === "web") {
    return "mw";
  }

  if (platform === "android") {
    return "an";
  }

  return platform;
}

export const triggerDWTakeover = () => {
  if (hotmob_tag && hotmob_tag?.define) {
    hotmob_tag.cmd.push(function () {
      hotmob_tag.define(
        "timable_dw_takeover",
        [
          [320, 480],
          [320, 460],
          [1, 1],
        ],
        "popup",
        "div-hotmob-ad-timable_dw_takeover-0"
      );
      hotmob_tag.features.stopAllBannerWhenPopupShow(true);
      hotmob_tag.features.setStopCallingOnOSs(["android", "ios"]);
      const splitOnSlash = window?.location?.pathname.split("/");

      // FIXME
      if (
        path.some((name) => window?.location?.pathname.includes(name)) ||
        splitOnSlash?.length === 3 ||
        splitOnSlash[3] === ""
      ) {
        // hotmob_tag.callback.defineAfterAdShowCallback(function () {
        //   console.log("[dw_takeover] called", hotmob_tag.cmd);
        // });
        hotmob_tag.display("div-hotmob-ad-timable_dw_takeover-0");
      }
    });

    document.cookie = `div-hotmob-ad-timable_dw_takeover-1-coldfreq=4; path=/`;
  }
};

export const countDWTakeover = () => {
  if (hotmob_tag && hotmob_tag?.define) {
    hotmob_tag.define(
      "timable_dw_takeover",
      [
        [320, 480],
        [320, 460],
        [1, 1],
      ],
      "popup",
      "div-hotmob-ad-timable_dw_takeover-1"
    );
    hotmob_tag.features.setColdFreq(3);
    hotmob_tag.features.setStopCallingOnOSs(["android", "ios"]);
    hotmob_tag.features.stopAllBannerWhenPopupShow(true);
    hotmob_tag.display("div-hotmob-ad-timable_dw_takeover-1");
  }
};

export const triggerMWPopover = (platform?: string) => {
  let code = adPlatform(platform);
  document.cookie = `div-hotmob-ad-timable_${code}_interstitial-0-coldfreq=4; path=/`;
  if (hotmob_tag && hotmob_tag?.define) {
    // console.log("popup code===>", `timable_${code}_popup`);

    switch (code) {
      case "ios":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_ios_popup`,
            [
              [320, 480],
              [320, 460],
              [1, 1],
            ],
            "popup",
            `div-hotmob-ad-timable_ios_popup-0`
          );

          hotmob_tag.features.setBackGroundOpacity(0.5);
          hotmob_tag.features.setBackGroundColor("#000");
          hotmob_tag.features.setStopCallingOnOSs(["Windows", "macos"]);
          hotmob_tag.features.stopAllBannerWhenPopupShow(true);
          hotmob_tag.features.setZIndex(9000000);

          // hotmob_tag.callback.defineAfterAdShowCallback(function () {
          //   console.log("[mw_popup] called", hotmob_tag.cmd);
          //   /** callback function **/
          // });
          hotmob_tag.display(`div-hotmob-ad-timable_ios_popup-0`);
        });
        break;
      case "an":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_an_popup`,
            [
              [320, 480],
              [320, 460],
              [1, 1],
            ],
            "popup",
            `div-hotmob-ad-timable_an_popup-0`
          );

          hotmob_tag.features.setBackGroundOpacity(0.5);
          hotmob_tag.features.setBackGroundColor("#000");
          hotmob_tag.features.setStopCallingOnOSs(["Windows", "macos"]);
          hotmob_tag.features.stopAllBannerWhenPopupShow(true);
          hotmob_tag.features.setZIndex(9000000);

          // hotmob_tag.callback.defineAfterAdShowCallback(function () {
          //   console.log("[mw_popup] called", hotmob_tag.cmd);
          //   /** callback function **/
          // });
          hotmob_tag.display(`div-hotmob-ad-timable_an_popup-0`);
        });
        break;
      case "mw":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_mw_popup`,
            [
              [320, 480],
              [320, 460],
              [1, 1],
            ],
            "popup",
            `div-hotmob-ad-timable_mw_popup-0`
          );

          hotmob_tag.features.setBackGroundOpacity(0.5);
          hotmob_tag.features.setBackGroundColor("#000");
          hotmob_tag.features.setStopCallingOnOSs(["Windows", "macos"]);
          hotmob_tag.features.stopAllBannerWhenPopupShow(true);
          hotmob_tag.features.setZIndex(9000000);

          // hotmob_tag.callback.defineAfterAdShowCallback(function () {
          //   console.log("[mw_popup] called", hotmob_tag.cmd);
          //   /** callback function **/
          // });
          hotmob_tag.display(`div-hotmob-ad-timable_mw_popup-0`);
        });
        break;
      default:
        break;
    }

    // hotmob_tag.cmd.push(function () {
    //   hotmob_tag.define(
    //     `timable_${code}_popup`,
    //     [
    //       [320, 480],
    //       [320, 460],
    //       [1, 1],
    //     ],
    //     "popup",
    //     `div-hotmob-ad-timable_${code}_popup-0`
    //   );

    //   hotmob_tag.features.setBackGroundOpacity(0.5);
    //   hotmob_tag.features.setBackGroundColor("#000");
    //   hotmob_tag.features.setStopCallingOnOSs(["Windows", "macos"]);
    //   hotmob_tag.features.stopAllBannerWhenPopupShow(true);
    //   hotmob_tag.features.setZIndex(9000000);

    //   // hotmob_tag.callback.defineAfterAdShowCallback(function () {
    //   //   console.log("[mw_popup] called", hotmob_tag.cmd);
    //   //   /** callback function **/
    //   // });
    //   hotmob_tag.display(`div-hotmob-ad-timable_${code}_popup-0`);
    // });
  }
};

export const triggerMWInterstitial = (platform?: string) => {
  let code = adPlatform(platform);
  if (hotmob_tag && hotmob_tag?.define) {
    // console.log("Interstitial code===>", `timable_${code}_interstitial`);

    switch (code) {
      case "ios":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_ios_interstitial`,
            [
              [320, 480],
              [320, 460],
              [1, 1],
            ],
            "popup",
            `div-hotmob-ad-timable_ios_interstitial-0`
          );
          hotmob_tag.features.setColdFreq(3);
          hotmob_tag.features.setBackGroundOpacity(0.5);
          hotmob_tag.features.setBackGroundColor("#000");
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.features.stopAllBannerWhenPopupShow(true);
          hotmob_tag.features.setZIndex(9000000);
          hotmob_tag.display(`div-hotmob-ad-timable_ios_interstitial-0`);
        });
        break;
      case "an":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_an_interstitial`,
            [
              [320, 480],
              [320, 460],
              [1, 1],
            ],
            "popup",
            `div-hotmob-ad-timable_an_interstitial-0`
          );
          hotmob_tag.features.setColdFreq(3);
          hotmob_tag.features.setBackGroundOpacity(0.5);
          hotmob_tag.features.setBackGroundColor("#000");
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.features.stopAllBannerWhenPopupShow(true);
          hotmob_tag.features.setZIndex(9000000);
          hotmob_tag.display(`div-hotmob-ad-timable_an_interstitial-0`);
        });
        break;
      case "mw":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_mw_interstitial`,
            [
              [320, 480],
              [320, 460],
              [1, 1],
            ],
            "popup",
            `div-hotmob-ad-timable_mw_interstitial-0`
          );
          hotmob_tag.features.setColdFreq(3);
          hotmob_tag.features.setBackGroundOpacity(0.5);
          hotmob_tag.features.setBackGroundColor("#000");
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.features.stopAllBannerWhenPopup;
        });
        break;
      default:
        break;
    }

    // hotmob_tag.cmd.push(function () {
    //   hotmob_tag.define(
    //     `timable_${code}_interstitial`,
    //     [
    //       [320, 480],
    //       [320, 460],
    //       [1, 1],
    //     ],
    //     "popup",
    //     `div-hotmob-ad-timable_${code}_interstitial-0`
    //   );
    //   hotmob_tag.features.setColdFreq(3);
    //   hotmob_tag.features.setBackGroundOpacity(0.5);
    //   hotmob_tag.features.setBackGroundColor("#000");
    //   hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
    //   hotmob_tag.features.stopAllBannerWhenPopupShow(true);
    //   hotmob_tag.features.setZIndex(9000000);
    //   hotmob_tag.display(`div-hotmob-ad-timable_${code}_interstitial-0`);
    // });
  }
};

export const mwMainFloatingAds = (platform?: string) => {
  let code = adPlatform(platform);
  if (hotmob_tag && hotmob_tag?.define) {
    // console.log("main floating code===>", `timable_${code}_main_floating`);

    switch (code) {
      case "ios":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_ios_main_floating`,
            [
              [300, 250],
              [320, 180],
              [320, 100],
              [320, 460],
              [1, 1],
            ],
            "bottomBanner",
            `div-hotmob-ad-timable_ios_main_floating-0`
          );
          hotmob_tag.features.setSmartBannerMaxWidth(414);
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.display(`div-hotmob-ad-timable_ios_main_floating-0`);
        });
        break;
      case "an":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_an_main_floating`,
            [
              [300, 250],
              [320, 180],
              [320, 100],
              [320, 460],
              [1, 1],
            ],
            "bottomBanner",
            `div-hotmob-ad-timable_an_main_floating-0`
          );
          hotmob_tag.features.setSmartBannerMaxWidth(414);
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.display(`div-hotmob-ad-timable_an_main_floating-0`);
        });
        break;
      case "mw":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_mw_main_floating`,
            [
              [300, 250],
              [320, 180],
              [320, 100],
              [320, 460],
              [1, 1],
            ],
            "bottomBanner",
            `div-hotmob-ad-timable_mw_main_floating-0`
          );
          hotmob_tag.features.setSmartBannerMaxWidth(414);
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.display(`div-hotmob-ad-timable_mw_main_floating-0`);
        });
        break;
      default:
        break;
    }

    // hotmob_tag.cmd.push(function () {
    //   hotmob_tag.define(
    //     `timable_${code}_main_floating`,
    //     [
    //       [300, 250],
    //       [320, 180],
    //       [320, 100],
    //       [320, 460],
    //       [1, 1],
    //     ],
    //     "bottomBanner",
    //     `div-hotmob-ad-timable_${code}_main_floating-0`
    //   );
    //   hotmob_tag.features.setSmartBannerMaxWidth(414);
    //   hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
    //   // console.log(`show ===> timable_${code}_main_floating - ${Date.now()}`);
    //   // hotmob_tag.callback.defineAfterAdShowCallback(function () {
    //   //   console.log("[mw_main_floating] called", hotmob_tag.cmd);
    //   //   /** callback function **/
    //   // });
    //   hotmob_tag.display(`div-hotmob-ad-timable_${code}_main_floating-0`);
    // });
  }
};

export const mwDetailFloatingAds = (platform?: string) => {
  let code = adPlatform(platform);
  if (hotmob_tag && hotmob_tag?.define) {
    console.log("detail floating code===>", `timable_${code}_detail_floating`);

    switch (code) {
      case "ios":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_ios_detail_floating`,
            [
              [300, 250],
              [320, 180],
              [320, 100],
              [320, 460],
              [1, 1],
            ],
            "bottomBanner",
            `div-hotmob-ad-timable_ios_detail_floating-0`
          );
          hotmob_tag.features.setSmartBannerMaxWidth(414);
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.display(`div-hotmob-ad-timable_ios_detail_floating-0`);
        });
        break;
      case "an":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_an_detail_floating`,
            [
              [300, 250],
              [320, 180],
              [320, 100],
              [320, 460],
              [1, 1],
            ],
            "bottomBanner",
            `div-hotmob-ad-timable_an_detail_floating-0`
          );
          hotmob_tag.features.setSmartBannerMaxWidth(414);
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.display(`div-hotmob-ad-timable_an_detail_floating-0`);
        });
        break;
      case "mw":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_mw_detail_floating`,
            [
              [300, 250],
              [320, 180],
              [320, 100],
              [320, 460],
              [1, 1],
            ],
            "bottomBanner",
            `div-hotmob-ad-timable_mw_detail_floating-0`
          );
          hotmob_tag.features.setSmartBannerMaxWidth(414);
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.display(`div-hotmob-ad-timable_mw_detail_floating-0`);
        });
        break;
      default:
        break;
    }

    // hotmob_tag.cmd.push(function () {
    //   hotmob_tag.define(
    //     `timable_${code}_detail_floating`,
    //     [
    //       [300, 250],
    //       [320, 180],
    //       [320, 100],
    //       [320, 460],
    //       [1, 1],
    //     ],
    //     "bottomBanner",
    //     `div-hotmob-ad-timable_${code}_detail_floating-0`
    //   );
    //   hotmob_tag.features.setSmartBannerMaxWidth(414);
    //   hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
    //   hotmob_tag.display(`div-hotmob-ad-timable_${code}_detail_floating-0`);
    // });
  }
};

export const mwListFloatingAds = (platform?: string) => {
  let code = adPlatform(platform);
  if (hotmob_tag && hotmob_tag?.define) {
    console.log("list floating code===>", `timable_${code}_list_floating`);

    switch (code) {
      case "ios":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_ios_list_floating`,
            [
              [300, 250],
              [320, 180],
              [320, 100],
              [320, 460],
              [1, 1],
            ],
            "bottomBanner",
            `div-hotmob-ad-timable_ios_list_floating-0`
          );
          hotmob_tag.features.setSmartBannerMaxWidth(414);
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.display(`div-hotmob-ad-timable_ios_list_floating-0`);
        });
        break;
      case "an":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_an_list_floating`,
            [
              [300, 250],
              [320, 180],
              [320, 100],
              [320, 460],
              [1, 1],
            ],
            "bottomBanner",
            `div-hotmob-ad-timable_an_list_floating-0`
          );
          hotmob_tag.features.setSmartBannerMaxWidth(414);
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.display(`div-hotmob-ad-timable_an_list_floating-0`);
        });
        break;
      case "mw":
        hotmob_tag.cmd.push(function () {
          hotmob_tag.define(
            `timable_mw_list_floating`,
            [
              [300, 250],
              [320, 180],
              [320, 100],
              [320, 460],
              [1, 1],
            ],
            "bottomBanner",
            `div-hotmob-ad-timable_mw_list_floating-0`
          );
          hotmob_tag.features.setSmartBannerMaxWidth(414);
          hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);
          hotmob_tag.display(`div-hotmob-ad-timable_mw_list_floating-0`);
        });
        break;
      default:
        break;
    }

    // hotmob_tag.cmd.push(function () {
    //   hotmob_tag.define(
    //     `timable_${code}_list_floating`,
    //     [
    //       [300, 250],
    //       [320, 180],
    //       [320, 100],
    //       [320, 460],
    //       [1, 1],
    //     ],
    //     "bottomBanner",
    //     `div-hotmob-ad-timable_${code}_list_floating-0`
    //   );
    //   hotmob_tag.features.setSmartBannerMaxWidth(414);
    //   hotmob_tag.features.setStopCallingOnOSs(["windows", "macos"]);

    //   hotmob_tag.display(`div-hotmob-ad-timable_${code}_list_floating-0`);
    // });
  }
};

export const removeFloatingAd = () => {
  const iframes = document?.querySelectorAll("iframe");
  const containers = document?.querySelectorAll(".hotmob-ad-container");
  const popupAds = document?.querySelectorAll(".hotmob-ad-popup-close-button");
  const detailPageFloating = document?.querySelectorAll(
    ".hotmob-website-bottom-banner-layer"
  );
  if (popupAds) {
    popupAds.forEach((ad) => {
      ad.dispatchEvent(new Event("click"));
    });
  }

  if (detailPageFloating) {
    detailPageFloating.forEach((ad) => {
      ad.remove();
    });
  }

  if (iframes) {
    iframes.forEach((iframe) => {
      iframe.remove();
    });
  }

  if (containers) {
    containers.forEach((container) => {
      container.remove();
    });
  }
};